import DatePicker from "react-datepicker2";

const PersianDatePicker = ({ selected, onChange, dataRanges }) => {
  return (
    <DatePicker
      value={selected}
      ranges={dataRanges}
      isGregorian={false}
      onChange={onChange}
      placeholder="انتخاب تاریخ"
      persianDigits
      timePicker={false}
      showTodayButton={false}
      minDate={new Date()}
    />
  );
};

export default PersianDatePicker;
