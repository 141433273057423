import Isotope from "isotope-layout";
import GLightbox from "glightbox";
import Swiper from "swiper";
import AOS from "aos";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";

const Section5Page = () => {
  useEffect(() => {
    const portfolioContainer = document.querySelector(".portfolio-container");

    if (portfolioContainer) {
      const portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: ".portfolio-item",
      });

      const portfolioFilters = document.querySelectorAll(
        "#portfolio-flters li"
      );

      const handleFilterClick = (e) => {
        e.preventDefault();

        portfolioFilters.forEach((el) => {
          el.classList.remove("filter-active");
        });

        e.currentTarget.classList.add("filter-active");

        portfolioIsotope.arrange({
          filter: e.currentTarget.getAttribute("data-filter"),
        });

        portfolioIsotope.on("arrangeComplete", () => {
          AOS.refresh();
        });
      };

      portfolioFilters.forEach((filter) => {
        filter.addEventListener("click", handleFilterClick, true);
      });
    }

    const portfolioLightbox = GLightbox({
      selector: ".portfolio-lightbox",
    });

    const portfolioDetailsLightbox = GLightbox({
      selector: ".portfolio-details-lightbox",
      width: "90%",
      height: "90vh",
    });

    new Swiper(".portfolio-details-slider", {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });

    window.onload = function () {
      var elementToClick = document.getElementById("all-data");

      if (elementToClick) {
        elementToClick.click();
      } else {
        console.log("Element not found");
      }
    };
  }, []);

  return (
    <div id="portfolio" class="portfolio section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title"></div>

        <div class="row">
          <div
            class="col-lg-12 d-flex justify-content-center"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <ul id="portfolio-flters">
              <li data-filter="*" id="all-data" class="filter-active">
                همه
              </li>
              <li data-filter=".filter-app">فرمالیته</li>
              <li data-filter=".filter-card">نامزدی</li>
              <li data-filter=".filter-web">سالگرد</li>
            </ul>
          </div>
        </div>

        <div
          class="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="col-lg-3 col-md-4 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img
                src="/asset/media/image/aros-damad.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info"></div>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img
                src="/asset/media/image/aros-damad2.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info"></div>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img
                src="/asset/media/image/aros-damad-gol.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info"></div>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img
                src="/asset/media/image/aros-flower.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info"></div>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img
                src="/asset/media/image/aros-gol.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info"></div>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img
                src="/asset/media/image/aros-halqe.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info"></div>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img
                src="/asset/media/image/aros-pele.jpg"
                class="img-fluid"
                alt=""
              />
              <div class="portfolio-info"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5Page;
