import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardPage from "./Card/CardPage";
import CardPage5 from "./Card5/CardPage5";
import CardPage6 from "./Card6/CardPage6";
import CardPage7 from "./Card7/CardPage7";
import CardPage8 from "./Card8/CardPage8";
import CardPage3 from "./Card3/CardPage3";
import Axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import CardPage2 from "./Card2/CardPage2";
import CardPage4 from "./Card4/CardPage4";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const GuestCardPage = () => {
  const { username, parameter } = useParams();
  const [guestCart, setGuestCart] = useState(null);

  useEffect(() => {
    Axios.get(`${BASE_URL}/api/cart/${username}/${parameter}`)
      .then((response) => {
        setGuestCart(response?.data?.cart);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [username, parameter]);

  const renderComponent = () => {
    switch (Number(guestCart?.wedding?.template_id)) {
      case 1:
        return <CardPage guestCart={guestCart} />;
      case 2:
        return <CardPage2 guestCart={guestCart} />;
      case 3:
        return <CardPage3 guestCart={guestCart} />;
      case 4:
        return <CardPage4 guestCart={guestCart} />;
      case 5:
        return <CardPage5 guestCart={guestCart} />;
      case 6:
        return <CardPage6 guestCart={guestCart} />;
      case 7:
        return <CardPage7 guestCart={guestCart} />;
      case 8:
        return <CardPage8 guestCart={guestCart} />;
      default:
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        );
    }
  };

  return <>{renderComponent()}</>;
};

export default GuestCardPage;
