import React from "react";
import Layout from "../../Layout/Layout";
import { Box, Grid, Typography } from "@mui/material";
import "../Bloges/FindLocation.css";

const FindLocationPage = () => {
  return (
    <Layout>
      <Box
        sx={{
          maxWidth: "650px",
          margin: "100px auto",
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          borderRadius: "10px",
          padding: "20px 15px",
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h4">
              اشتراک گذاشتن مکان در برنامه های مسیریاب
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h5" mr={1}>
                1.
              </Typography>
              <img alt="" src="/asset/media/icon/google-map.png" width="50px" />
              <Typography variant="h5" mr={1}>
                گوگل مپ
              </Typography>
            </Box>
            <Typography variant="body1">
              در گوشی یا تبلت خود برنامه
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                Maps
              </Typography>
              را باز کرده
            </Typography>
            <Typography variant="body1">
              قسمت
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                search here
              </Typography>
              را لمس کرده و اسم تالار مورد نظر را سرچ کرده و روی نتیجه کلیک کنید
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/google.maps1.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/google.maps2.jpg"
              width="100%"
            />
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/google.maps3.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              <Typography
                variant="span"
                sx={{ color: "#EA2027", marginLeft: "8px" }}
              >
                share
              </Typography>
              را لمس کرده
            </Typography>
            <Typography variant="body1">
              <Typography
                variant="span"
                sx={{ color: "#EA2027", marginLeft: "8px" }}
              >
                more
              </Typography>
              را لمس کرده
            </Typography>
            <Typography variant="body1">
              برنامه
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                note
              </Typography>
              را انتخاب کرده
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/google.maps4.jpg"
              width="100%"
            />
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/google.maps5.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/google.maps6.jpg"
              width="100%"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              لینک را کپی کنید و آن را به اشتراک بگذارید
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/google.maps7.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={10} sx={{ borderBottom: "2px solid #323232" }}></Grid>

          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", alignItems: "center", marginTop: "50px" }}
            >
              <Typography variant="h5" mr={1}>
                2.
              </Typography>
              <img alt="" src="/asset/media/icon/waze.png" width="50px" />
              <Typography variant="h5" mr={1}>
                Waze
              </Typography>
            </Box>
            <Typography variant="body1">ابتدا فیلترشکن را روشن کرده</Typography>
            <Typography variant="body1">
              در گوشی یا تبلت خود برنامه
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                Waze
              </Typography>
              را باز کرده
            </Typography>
            <Typography variant="body1">
              قسمت
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                where to?
              </Typography>
              را لمس کرده و اسم تالار مورد نظر را سرچ کرده و روی نتیجه کلیک کنید
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/waze1.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/waze2.jpg"
              width="100%"
            />
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/waze3.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              {" "}
              سه نقطه
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0 8px" }}
              >
                ...
              </Typography>
              را لمس کرده
            </Typography>
            <Typography variant="body1">
              <Typography
                variant="span"
                sx={{ color: "#EA2027", marginLeft: "8px" }}
              >
                send
              </Typography>
              را لمس کرده
            </Typography>
            <Typography variant="body1">
              اگر برنامه نوت را پیدا نکردید
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                more options
              </Typography>
              را انتخاب کرده
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/waze4.jpg"
              width="100%"
            />
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/waze5.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/waze6.jpg"
              width="100%"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              برنامه
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                note
              </Typography>
              را انتخاب کرده
            </Typography>
            <Typography variant="body1">
              لینک را کپی کنید و آن را به اشتراک بگذارید
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/waze7.jpg"
              width="100%"
            />
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/waze8.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={10} sx={{ borderBottom: "2px solid #323232" }}></Grid>

          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", alignItems: "center", marginTop: "50px" }}
            >
              <Typography variant="h5" mr={1}>
                3.
              </Typography>
              <img alt="" src="/asset/media/icon/balad.png" width="50px" />
              <Typography variant="h5" mr={1}>
                بلد
              </Typography>
            </Box>
            <Typography variant="body1">
              در گوشی یا تبلت خود برنامه
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                بلد
              </Typography>
              را باز کرده
            </Typography>
            <Typography variant="body1">
              قسمت
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                جستجو
              </Typography>
              را لمس کرده و اسم تالار مورد نظر را سرچ کرده و روی نتیجه کلیک کنید
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/balad1.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/balad2.jpg"
              width="100%"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0 8px" }}
              >
                اشتراک گذاری{" "}
              </Typography>
              را لمس کرده
            </Typography>
            <Typography variant="body1">
              برنامه
              <Typography
                variant="span"
                sx={{ color: "#EA2027", margin: "0px 8px" }}
              >
                note
              </Typography>
              را انتخاب کرده
            </Typography>
            <Typography variant="body1">
              لینک را کپی کنید و آن را به اشتراک بگذارید
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/balad3.jpg"
              width="100%"
            />
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/balad4.jpg"
              width="100%"
            />
          </Grid>
          <Grid item xs={3}>
            <img
              alt=""
              src="/asset/media/image/location/balad5.jpg"
              width="100%"
            />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default FindLocationPage;
