import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Navigation.css";
import { useCart } from "../../Providers/CartProvider";
import { useAuth } from "../../auth/authContext";

const Navigation = () => {
  const { cart } = useCart();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { user, isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleGoToPlans = () => {
    if (location.pathname !== "/") {
      navigate("/", { replace: true });
    }
    setTimeout(() => {
      const element = document.getElementById("plans");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <header className={`navBar ${isScrolled ? "scroll" : ""}`}>
        <nav>
          <ul>
            <li>
              <NavLink to="/" activeclassname="activeLink">
                صفحه اصلی
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/about-us" activeclassname="activeLink">
                درباره ما
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/contact-us" activeclassname="activeLink">
                ارتباط با ما
              </NavLink>
            </li>
            <li>
              <a
                href="https://www.instagram.com/aroosi.online/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                rel="noreferrer"
              >
                پیج اینستاگرام
              </a>
            </li>
            <li>
              <button
                style={{ backgroundColor: "transparent" }}
                onClick={handleGoToPlans}
              >
                پکیج
              </button>
            </li>
            {/* <li>
            <NavLink to="/blog" activeclassname="activeLink">وبلاگ</NavLink>
          </li>
          <li>
            <NavLink to="/card" activeclassname="activeLink">کارت عروسی</NavLink>
          </li>
          <li>
            <NavLink to="/dashboard" activeclassname="activeLink">داشبورد</NavLink>
          </li>
          <li>
            <NavLink to="/card3" activeclassname="activeLink">card3</NavLink>
          </li>*/}
            {/* <li>
            <NavLink to="/cart" activeclassname="activeLink">سبد
              <span style={{ backgroundColor: cart.length === 1 ? "green" : "red" }} className='activeBasket'></span>
            </NavLink>
          </li>  */}
          </ul>
        </nav>
        <div className="logo">
          <NavLink to="/">Aroosi.online</NavLink>
        </div>
        <div className="leftSide">
          {isAuthenticated ? (
            <div>
              {/* <span>{user.name}</span> */}
              <button type="button" className="logOut" onClick={handleLogout}>
                خروج
              </button>
              <NavLink to="/dashboard" activeclassname="activeLink">
                <img
                  alt=""
                  className="accountIcon"
                  src="/asset/media/pic/icon/bride-account.png"
                />
              </NavLink>
            </div>
          ) : (
            <div className="loginButton">
              <NavLink to="/login" activeclassname="activeLink">
                ورود
              </NavLink>
            </div>
          )}
        </div>
        <button className="hambergerBtn" onClick={toggleSidebar}>
          ☰
        </button>
      </header>

      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <nav>
          <ul>
            <li>
              <NavLink to="/" activeclassname="activeLink">
                صفحه اصلی
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/about-us" activeclassname="activeLink">
                درباره ما
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink to="/blog" activeclassname="activeLink">وبلاگ</NavLink>
            </li> */}
            <li>
              <NavLink to="/contact-us" activeclassname="activeLink">
                ارتباط با ما
              </NavLink>
            </li>
            <li>
              <a
                href="https://www.instagram.com/aroosi.online/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
              >
                پیج اینستاگرام
              </a>
            </li>
            <li>
              <button
                style={{ backgroundColor: "transparent" }}
                onClick={handleGoToPlans}
              >
                پکیج
              </button>
            </li>

            {isAuthenticated ? (
              <>
                <li>
                  <NavLink to="/dashboard" activeclassname="activeLink">
                    پنل کاربری
                  </NavLink>
                </li>

                <li>
                  <button onClick={handleLogout}>خروج</button>
                </li>
              </>
            ) : (
              <div className="loginButton">
                <NavLink to="/login" activeclassname="activeLink">
                  ورود
                </NavLink>
              </div>
            )}
          </ul>
        </nav>
        <button className="close" onClick={toggleSidebar}>
          <img alt="" className="" src="/asset/media/pic/icon/close.png" />
        </button>
      </div>

      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
    </div>
  );
};

export default Navigation;
