import { useState } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAuth } from "../../../../auth/authContext";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const Section3Page = ({ cartData }) => {
  const { isAuthenticated } = useAuth();

  const initialValues = {
    name: cartData?.mementos[0]?.name || "",
    comment: cartData?.mementos[0]?.text || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string(),
    comment: Yup.string(),
  });

  const handleSubmit = (values) => {
    axios
      .post(`${BASE_URL}/api/memento`, {
        name: values?.name,
        text: values?.comment,
        wedding_id: cartData?.wedding_id,
        code: cartData?.code,
      })
      .then((response) => {
        toast.success(response?.data?.message);
        // fetchCartData();
      })
      .catch((error) => {
        if (isAuthenticated) {
          toast.error("تنها مهمان‌ها میتوانند نظر ثبت کنند.");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="sectionTree" data-aos="zoom-in-right">
          <img
            src="/asset/media/pic/cart2/flower3.svg"
            className="cardImage1"
            alt=""
          />
          <img
            src="/asset/media/pic/cart2/flower3.svg"
            className="cardImage2"
            alt=""
          />

          <h2 className="title">یادبودها</h2>

          <div style={{ maxWidth: "250px" }}>
            <Field type="text" name="name" id="name" placeholder="نام" />
            <Field as="textarea" placeholder="متن پیام" name="comment" />
          </div>

          <button type="submit" className="send">
            ارسال
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default Section3Page;
