import { useNavigate } from "react-router-dom";
// import "../Plan/PlanPage.css"
import { Box, Grid, Typography } from "@material-ui/core";
import { Formik } from "formik";
import Layout from "../Layout/Layout";

const AboutUsPage = () => {
  return (
    <Layout>
      <main className="planPage">
        <img
          alt=""
          className="flowerLeft"
          src="/asset/media/pic/flower-header1.png"
        />
        <Grid container style={{ marginBottom: "100px" }}>
          <Grid item lg={12} xs={10}>
            <Typography variant="h3">درباره ما</Typography>
            <Box style={{ margin: "20px 0" }}>
              <Typography variant="p" style={{ display: "block" }}>
                رسالت عروسی آنلاین راحتی عروس‌ها و دامادهای عزیز
              </Typography>
              <Typography variant="p">
                {" "}
                و کمک به برگزاری هرچه بهتر مراسم عروسی شما و دعوت از مهمان‌های
                شما در سریع‌ترین زمان ممکن است.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={12} xs={10}>
            <Typography variant="h6">راه‌های ارتباطی:</Typography>
          </Grid>
          <Grid item lg={12} xs={10}>
            <Typography variant="p" style={{ marginLeft: "15px" }}>
              تلگرام:
            </Typography>
            {/* <Typography variant="p">۰۹۳۶۴۴۹۱۵۸۰</Typography> */}
            <a
              href="https://t.me/aroosiOnline"
              target="_blank"
              rel="noreferrer"
            >
              aroosiOnline
            </a>
          </Grid>
          <Grid item lg={12} xs={10}>
            <Typography variant="p" style={{ marginLeft: "15px" }}>
              ایمیل:
            </Typography>
            <a href="mailto: onlinearoosi@gmail.com">
              {" "}
              onlinearoosi@gmail.com{" "}
            </a>
            {/* <Typography variant="p">onlinearoosi@gmail.com</Typography> */}
          </Grid>
          <Grid item lg={12} xs={10}>
            <Typography variant="p" style={{ marginLeft: "15px" }}>
              پیح اینستاگرام:
            </Typography>
            <a
              href="https://www.instagram.com/aroosi.online/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
              rel="noreferrer"
            >
              aroosi.online
            </a>
            <Grid>
              {/* <Grid item lg={12} xs={10}>
            <Typography variant="p" style={{ marginLeft: "15px" }}>
              آدرس:
            </Typography>
            <Typography variant="p">
              تهران - نازی آباد - خیابان عراقی - پلاک 15
            </Typography>
            <Grid> */}
              <a
                referrerpolicy="origin"
                target="_blank"
                href="https://trustseal.enamad.ir/?id=552018&Code=X1EDCnAIPyCG3iMwQsoBNrn7WII01iAe"
              >
                <img
                  referrerpolicy="origin"
                  src="https://trustseal.enamad.ir/logo.aspx?id=552018&Code=X1EDCnAIPyCG3iMwQsoBNrn7WII01iAe"
                  alt=""
                  style={{ cursor: "pointer" }}
                  code="X1EDCnAIPyCG3iMwQsoBNrn7WII01iAe"
                />
              </a>
            </Grid>
          </Grid>
          <Grid tem xs={12}>
            <a
              referrerpolicy="origin"
              target="_blank"
              href="https://trustseal.enamad.ir/?id=552018&Code=X1EDCnAIPyCG3iMwQsoBNrn7WII01iAe"
              rel="noreferrer"
            >
              <img
                referrerpolicy="origin"
                src="https://trustseal.enamad.ir/logo.aspx?id=552018&Code=X1EDCnAIPyCG3iMwQsoBNrn7WII01iAe"
                alt=""
                style={{ cursor: "pointer" }}
                code="X1EDCnAIPyCG3iMwQsoBNrn7WII01iAe"
              />
            </a>
          </Grid>
        </Grid>
      </main>
    </Layout>
  );
};

export default AboutUsPage;
