import Input from "../../common/input";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Toast, toast } from "react-toastify";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const initialValues = {
  mobile: "",
};

const validationSchema = Yup.object({
  // mobile: Yup.string().required('شماره تلفن را وارد کنید')
  //     .matches(/^[0-9]{11}$/, 'شماره اشتباه وارد شده است')
  //     .nullable(),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.redirectPath || "/dashboard";

  const onSubmit = (values) => {
    axios
      .post(`${BASE_URL}/api/auth/login-request`, values)
      .then((response) => {
        toast.success(response?.data?.message);
        const { mobile } = values;
        // login(response?.data?.status , response?.data?.token);
        // navigate('/verify', { replace: false });
        navigate("/verify", { state: { mobile, redirectPath } });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnMount: true,
  });

  return (
    <section className="loginPage">
      <Link to="/" className="backBtn">
        بازگشت
      </Link>

      <div className="loginPageOut">
        <img
          alt=""
          className="flowerLeft"
          src="/asset/media/pic/login-flower1.webp"
        />
        <img
          alt=""
          className="flowerRight"
          src="/asset/media/pic/login-flower2.webp"
        />

        <form className="loginPageIn" onSubmit={formik.handleSubmit}>
          <div className="title">
            <h1>ورود</h1>
            <img
              alt=""
              className="flower"
              src="/asset/media/pic/login-title.webp"
            />
          </div>

          <Input
            formik={formik}
            name="mobile"
            label="شماره موبایل"
            type="tel"
          ></Input>

          <button className="login" type="submit" disabled={!formik.isValid}>
            ادامه
          </button>
        </form>
      </div>
    </section>
  );
};

export default LoginForm;
