import { Box, Typography, Grid } from "@mui/material";

const WhyUsComponent = () => {
  return (
    <section className="whyUs">
      <Box style={{ width: "1000px", maxWidth: "100%", margin: "0 auto" }}>
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              چرا عروسی آنلاین ؟
            </Typography>
            <Typography>
              سوال اینجاست که چرا با وجود کارت عروسی فیزیکی باید کارت عروسی
              آنلاین مارو انتخاب کنید؟
            </Typography>
            <Typography>
              <img
                alt=""
                style={{ width: "30px", marginLeft: "10px" }}
                src="/asset/media/icon/correct.png"
              />
              همیشه یکی از دغدغه‌ها هنگام نوشتن اسم مهمان‌ها روی کارت کم آوردن
              کارت است و برای برطرف کردن این مشکل دردسر زیادی را باید متحمل
              بشوید درحالی که به راحتی داخل سایت میتوانید تعداد مهمان‌ها و کارت
              را تغییر بدهید
            </Typography>
            <Typography>
              <img
                alt=""
                style={{ width: "30px", marginLeft: "10px" }}
                src="/asset/media/icon/correct.png"
              />
              یکی از دغدغه‌های همیشگی مهمان‌ها پیدا نکردن آدرس تالار عروسی است.
              کارت عروسی ما با توجه به امکاناتی که قرار داده است این مشکل را به
              راحتی حل کرده و مهمان‌های شما با آرامش خاطر و به دور از هرگونه
              کلافگی از پیدا نکردن مسیر به مهمانی شما می‌رسند.
            </Typography>
            <Typography>
              <img
                alt=""
                style={{ width: "30px", marginLeft: "10px" }}
                src="/asset/media/icon/correct.png"
              />
              همیشه پخش کارت‌های عروسی برای برگذارکننده مراسم کاری بسیار زمانگیر
              و دشوار است و حتی در بعضی موارد کارت به دست مهمان نمیرسد. ولی در
              عروسی آنلاین با یک کلیک ساده کارت همه مهمان‌ها ارسال میشود و
              میتوانید مطعن شوید که کارت به دست آن‌ها رسیده باشد.
            </Typography>
            <Typography>
              <img
                alt=""
                style={{ width: "30px", marginLeft: "10px" }}
                src="/asset/media/icon/correct.png"
              />
              با امکان نظرسنجی از مهمان‌ها که عروسی آنلاین برای کارت‌های شما
              قرار داده میتوانید از شرکت یا عدم شرکت مهمان‌ها مطمعن شوید تا در
              صورت عدم شرکت آن‌ها در کمترین زمان ممکن مهمان‌های جدید جایگزین
              کنید.
            </Typography>
            <Typography>
              <img
                alt=""
                style={{ width: "30px", marginLeft: "10px" }}
                src="/asset/media/icon/correct.png"
              />
              ما این امکان را برای مهمان‌های شما فراهم کرده‌ایم تا با پیام کوتاه
              قبل از مراسم تاریخ و ساعت را به آنها یاداوری کنیم.
            </Typography>
            <Typography>
              <img
                alt=""
                style={{ width: "30px", marginLeft: "10px" }}
                src="/asset/media/icon/correct.png"
              />
              شما میتوانید عکس‌های زیبای خودتان را در گالری کارت قرار دهید تا
              زیبایی کارتتان را چندین برابر کنید.
            </Typography>
            <Typography>
              <img
                alt=""
                style={{ width: "30px", marginLeft: "10px" }}
                src="/asset/media/icon/correct.png"
              />
              با طبیعت مهربان باشید. شما میتوانید با عدم استفاده از کارت‌های
              کاغذی به قطع نکردن درخت‌ها،این موجودات ارزشمند کمک کنید.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default WhyUsComponent;
