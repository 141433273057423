import { useEffect, useRef, useState } from "react";

const ChangeMusic = ({ music }) => {
  const [playMusic, setPlayMusic] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (playMusic && audioRef.current) {
      audioRef.current.play();
    } else if (audioRef.current) {
      audioRef.current.pause();
    }
  }, [playMusic]);

  const handleMusic = () => {
    setPlayMusic((prev) => !prev);
  };

  return (
    <>
      {music ? (
        <div className="playMusicBox">
          <audio ref={audioRef} src={music} loop></audio>
          <button onClick={handleMusic}>
            {!playMusic ? (
              <img alt="Play/Pause Music" src="/asset/media/icon/music.png" />
            ) : (
              <img
                alt="Play/Pause Music"
                src="/asset/media/icon/no-music.png"
              />
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ChangeMusic;
