import { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import CustomSelector from "../../../components/Selector/CustomSelector.jsx";
import { useAuth } from "../../../auth/authContext.js";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

function SurveriesPage() {
  const { token, cartData } = useAuth();
  const [surveries, setSurveries] = useState([]);

  const [isloading, setIsloading] = useState(false);

  const [chartData, setChartData] = useState([]);
  const [chartSeries, setChartSeries] = useState();
  const [chartCategories, setChartCategories] = useState();

  const state = {
    series: chartSeries,
    labels: chartCategories,
    options: {
      xaxis: {
        categories: chartCategories,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: true,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          return (
            "  " +
            opts?.w?.globals.labels[opts.seriesIndex] +
            " : " +
            opts.w.globals.series[opts.seriesIndex] +
            " نفر"
          );
        },
      },
      title: {
        text: "",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  function findDataChart(value) {
    const result = chartData?.find((c) => c?.id === Number(value));
    const categories = result?.survey_answers?.map((item) => item?.answer);
    setChartCategories(categories);

    const series = result?.survey_results?.map(
      (item) => item?.participate_number
    );
    setChartSeries(series);
  }

  const handleFilteredSurvery = (selectedValue) => {
    setIsloading(true);
    axios
      .get(
        `${BASE_URL}/api/survey/answers?survey_id=${
          selectedValue ? selectedValue : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
        setSurveries(response?.data);
        setIsloading(false);
        findDataChart(response?.data[0]?.survey_id);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  // useEffect(() => {
  //     setFilteredGuests(surveries);
  // }, [surveries]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/licence/current`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const responseData = response?.data?.wedding;
        setSurveries(responseData?.survey_user_answers);
        setChartData(responseData?.surveys);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  return (
    <Box
      style={{
        width: "1200px",
        maxWidth: "100%",
        margin: "0 auto",
        position: "relative",
      }}
    >
      {cartData?.licence_id === 1 ? (
        <Box className="licenseDisable">
          <Link to="/plan/2" className="licenseLink">
            اپدیت پکیج
          </Link>
        </Box>
      ) : (
        ""
      )}
      <Typography variant="h5" sx={{ mb: 1, mt: 2, color: "#40407a" }}>
        نظرسنجی‌ها
      </Typography>

      <CustomSelector
        options={cartData?.surveys}
        placeholder={"فیلتر براساس نظرسنجی"}
        onSelection={handleFilteredSurvery}
        labelName="question"
        valueName="id"
      />

      {chartSeries && chartCategories && (
        <>
          <div id="chart">
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="donut"
              width={380}
            />
          </div>
          <div id="html-dist"></div>
        </>
      )}

      <TableContainer component={Paper} style={{ marginTop: "10px" }}>
        <Table style={{ width: "1000px" }}>
          <TableHead>
            <TableRow>
              <TableCell align="right">#</TableCell>
              <TableCell align="right">نام</TableCell>
              <TableCell align="right">عنوان</TableCell>
              <TableCell align="right" style={{ width: "50%" }}>
                پاسخ
              </TableCell>
            </TableRow>
          </TableHead>
          {isloading ? (
            <CircularProgress />
          ) : (
            <TableBody>
              {surveries?.map((survery, index) => (
                <TableRow key={index}>
                  <TableCell align="right">{index + 1}</TableCell>
                  <TableCell align="right">{survery?.cart?.name}</TableCell>
                  <TableCell align="right">
                    {survery?.survey?.question}
                  </TableCell>
                  <TableCell align="right">
                    {survery?.survey_answer?.answer}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SurveriesPage;
