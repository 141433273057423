import Navigation from "../components/Navigation/Navigation";

const NotFound404Page = () => {
  return (
    <>
      <Navigation />
      <div className="notFoundPage">
        <img alt="" className="notFoundImg" src="/asset/media/pic/Ghost.png" />
        <h2 style={{ color: "#273c75" }}>
          صفحه ای که دنبال آن میگردید پیدا نشد :(
        </h2>
      </div>
    </>
  );
};

export default NotFound404Page;
