// PrivateRoute.js
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useAuth } from "../auth/authContext";
import { useEffect } from "react";

// export const PrivateRoute = ({ children, ...rest }) => {
//   const { isAuthenticated } = useAuth();
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!isAuthenticated) navigate('/login');
//   }, [isAuthenticated, navigate])

//   return isAuthenticated ? (
//     <Routes>
//       <Route {...rest}>
//         {children}
//       </Route>
//     </Routes>
//   ) : (
//     <Navigate to="/login" />
//   );
// };

export const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : <Navigate to={{ pathname: "/login" }} />;
};
