import { useEffect, useRef, useState } from "react";
import "./CardPage3.css";
import Section1Page from "./components/Section1";
import Section2Page from "./components/Section2";
import Section3Page from "./components/Section3";
import Section4Page from "./components/section4";
import Section5Page from "./components/Section5";
import Section7Page from "./components/Section7";
import { useLocation } from "react-router-dom";
import Section6Page from "./components/Section6";
import ChangeMusic from "../../../common/ChangeMusic";

const CardPageExample3 = () => {
  const location = useLocation();
  const exampleDataCart = location?.state?.guestCart;
  const [cart, setCart] = useState("cardPage3");

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={`cardPage ${cart}`}>
      <nav className="sidebarCart">
        <ul>
          <li onClick={() => scrollToSection("section4")}>زمان</li>
          <li onClick={() => scrollToSection("section1")}>کارت</li>
          <li onClick={() => scrollToSection("section2")}>لوکیشن</li>
          <li onClick={() => scrollToSection("section3")}>یادبودها</li>
          {/* <li onClick={() => scrollToSection('section5')}>گالری</li> */}
          <li onClick={() => scrollToSection("section7")}>نظرسنجی</li>
        </ul>
      </nav>
      <nav className="sidebarCardMobile">
        <ul>
          <li onClick={() => scrollToSection("section4")}>زمان</li>
          <li onClick={() => scrollToSection("section1")}>کارت</li>
          <li onClick={() => scrollToSection("section2")}>لوکیشن</li>
          <li onClick={() => scrollToSection("section3")}>یادبودها</li>
          {/* <li onClick={() => scrollToSection('section5')}>گالری</li> */}
          <li onClick={() => scrollToSection("section7")}>نظرسنجی</li>
        </ul>
      </nav>
      <main>
        <div className="guestName">{exampleDataCart?.guestName}</div>

        <section id="section4">
          <Section4Page cartData={exampleDataCart?.wedding} />
        </section>
        <section id="section1">
          <Section1Page cartData={exampleDataCart?.wedding} />
        </section>
        <section id="section2">
          <Section2Page cartData={exampleDataCart?.wedding} />
        </section>
        <section id="section3">
          <Section3Page />
        </section>
        <section id="section7">
          <Section7Page cartData={exampleDataCart?.wedding} />
        </section>
        {/* <section id="section5">
          <Section5Page cartData={ exampleDataCart?.wedding}/>
        </section> */}
        <section id="section6">
          <Section6Page cartData={exampleDataCart?.wedding} />
        </section>
      </main>

      <ChangeMusic music={exampleDataCart?.wedding?.music }/>

    </div>
  );
};

export default CardPageExample3;
