const saveCartToLocalStorage = (cart) => {
  localStorage.setItem("cart", JSON.stringify(cart));
};

const loadCartFromLocalStorage = () => {
  const savedCart = localStorage.getItem("cart");
  return savedCart ? JSON.parse(savedCart) : [];
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART": {
      const updatedCart = [...state.cart];
      const index = updatedCart.findIndex(
        (item) => item.id === action.payload.id
      );
      updatedCart.splice(0);

      if (index < 0) {
        updatedCart.push({ ...action.payload, quantity: 1 });
      }
      saveCartToLocalStorage(updatedCart);
      return { ...state, cart: updatedCart };
    }
    case "REMOVE_CARTITEM": {
      const updatedCart = [...state.cart];
      const filteredCart = updatedCart.filter(
        (item) => item.id !== action.payload.id
      );
      saveCartToLocalStorage(filteredCart);
      return { ...state, cart: filteredCart };
    }
    default:
      return state;
  }
};

const initialState = {
  cart: loadCartFromLocalStorage(),
};

export default cartReducer;
