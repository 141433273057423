import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../../../../../auth/authContext";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const MusicPage = () => {
  const { token, fetchCartData, cartData } = useAuth();
  const [uploadedMusic, setUploadedMusic] = useState({});

  useEffect(() => {
    if (cartData) {
      setUploadedMusic({
        music: cartData?.music || null,
      });
    }
  }, [cartData]);

  const handleSendMusic = async () => {
    try {
      const formData = new FormData();
      if (uploadedMusic?.music) {
        formData.append("music", uploadedMusic?.music);
      }

      const response = await axios.post(
        `${BASE_URL}/api/licence/current`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Box className="formInformation">
      {cartData?.licence_id === 1 ? (
        <Box className="licenseDisable">
          <Link to="/plan/2" className="licenseLink">
            اپدیت پکیج
          </Link>
        </Box>
      ) : (
        ""
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">موزیک پس زمینه</Typography>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          item
          xs={12}
          md={12}
        >
          <MusicUpload
            uploadedMusic={uploadedMusic}
            setUploadedMusic={setUploadedMusic}
          />
        </Grid>

        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
          item
          xs={12}
        >
          <Button
            onClick={handleSendMusic}
            className="saveInformation"
            type="submit"
            variant="contained"
            color="primary"
          >
            ذخیره
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MusicPage;

export const MusicUpload = ({ uploadedMusic, setUploadedMusic }) => {
  const maxFileSize = 4 * 1024 * 1024; // 4MB

  // Dropzone settings
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "audio/*": [],
    },
    maxSize: maxFileSize,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length) {
        handleMusicUpload(acceptedFiles[0]);
      }
      if (rejectedFiles.length) {
        toast.error("File size must be less than 3MB");
      }
    },
  });

  // Handle the music file upload
  const handleMusicUpload = (file) => {
    if (file.size > maxFileSize) {
      toast.error("File size exceeds 3MB!");
      return;
    }
    setUploadedMusic((prevState) => ({
      ...prevState,
      music: file,
    }));
  };

  // Handle the music file removal
  const handleRemoveMusic = () => {
    setUploadedMusic((prevState) => ({
      ...prevState,
      music: null,
    }));
  };

  return (
    <>
      <Box
        {...getRootProps()}
        className={`upload-zone ${isDragActive ? "active" : ""}`}
        p={2}
        border={1}
        borderColor="gray"
        borderRadius={4}
        textAlign="center"
        width="100%"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>موسیقی خود را اینجا قرار دهید...</Typography>
        ) : (
          <Typography>
            برای آپلود موسیقی کلیک کنید یا فایل را بکشید (حداکثر 3MB)
          </Typography>
        )}
      </Box>
      {uploadedMusic.music && (
        <List>
          <ListItem>
            <ListItemText primary={uploadedMusic.music.name} />
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={handleRemoveMusic}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </ListItem>
        </List>
      )}
    </>
  );
};
