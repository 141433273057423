import React from "react";
import { useAuth } from "../../auth/authContext";
import ChangeMusic from "../../common/ChangeMusic";

const CardPageTemplate = ({ guestCart, sections, className }) => {
  const { cartData } = useAuth();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // شرط نمایش هر بخش بر اساس cartData و guestCart
  const isSectionVisible = (sectionId) => {
    switch (sectionId) {
      case "section3": // یادبودها
        return cartData?.show_memento || guestCart?.wedding?.show_memento;
      case "section7": // نظرسنجی
        return cartData?.show_survey || guestCart?.wedding?.show_survey;
      case "section6": // بخش ۶
        return cartData?.show_survey || guestCart?.wedding?.show_survey;
      default:
        return true; // پیش‌فرض نمایش
    }
  };

  // ایجاد عناصر ناوبری
  const renderNavItems = (isMobile = false) => (
    <nav className={isMobile ? "sidebarCardMobile" : "sidebarCart"}>
      <ul>
        {sections
          .filter((section) => section.showInNav)
          .map(
            (section) =>
              isSectionVisible(section.id) && (
                <li
                  key={section.id}
                  onClick={() => scrollToSection(section.id)}
                >
                  {section.label}
                </li>
              )
          )}
      </ul>
    </nav>
  );

  return (
    <div className={`cardPage ${className || ""}`}>
      {/* ناوبری */}
      {renderNavItems()}
      {renderNavItems(true)}

      {/* محتوا */}
      <main>
        {guestCart?.name && <div className="guestName">{guestCart?.name}</div>}

        {isSectionVisible("section4") && (
          <section id="section4">
            {React.createElement(sections.find((sec) => sec.id === "section4")?.component, {
              cartData: cartData || guestCart?.wedding,
            })}
          </section>
        )}

        {isSectionVisible("section1") && (
          <section id="section1">
            {React.createElement(sections.find((sec) => sec.id === "section1")?.component, {
              cartData: cartData || guestCart?.wedding,
            })}
          </section>
        )}

        {isSectionVisible("section2") && (
          <section id="section2">
            {React.createElement(sections.find((sec) => sec.id === "section2")?.component, {
              cartData: cartData || guestCart?.wedding,
            })}
          </section>
        )}

        {isSectionVisible("section3") && (
          <section id="section3">
            {React.createElement(sections.find((sec) => sec.id === "section3")?.component, {
              cartData: guestCart, // شرط خاص برای section3
            })}
          </section>
        )}

        {isSectionVisible("section7") && (
          <section id="section7">
            {React.createElement(sections.find((sec) => sec.id === "section7")?.component, {
              cartData: cartData || guestCart, // شرط خاص برای section7
            })}
          </section>
        )}

        {isSectionVisible("section6") && (
          <section id="section6">
            {React.createElement(sections.find((sec) => sec.id === "section6")?.component, {
              cartData: cartData || guestCart?.wedding,
            })}
          </section>
        )}
      </main>

      {/* موسیقی */}
      <ChangeMusic music={cartData?.music || guestCart?.wedding?.music} />
    </div>
  );
};

export default CardPageTemplate;
