import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../Layout/Layout.js";
import "../Ai/AiPage.css";
import { Grid } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useAuth } from "../../auth/authContext.js";
import axios from "axios";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  ArayeshgaAros,
  ArayeshgaDamad,
  Atelie,
  Dastegol,
  Food,
  LebasAros,
  Mashin,
  MashinGol,
  NumberOfGuest,
  Talar,
} from "./Questions.js";
import { Box } from "@mui/material";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const AiPage = () => {
  const { planId } = useParams();
  const navigate = useNavigate();
  const { token } = useAuth();

  const [response, setResponse] = useState({
    numberOfGuest: "",
    kindOfFood: "",
    talar: "",
    atelie: "",
    mashin: "",
    mashinGol: "",
    arayeshgaAros: "",
    arayeshgaDamad: "",
    dastegol: "",
    lebasAros: "",
  });

  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    <NumberOfGuest setResponse={setResponse} response={response} />,
    <Food setResponse={setResponse} response={response} />,
    <Talar setResponse={setResponse} response={response} />,
    <Atelie setResponse={setResponse} response={response} />,
    <Mashin setResponse={setResponse} response={response} />,
    <MashinGol setResponse={setResponse} response={response} />,
    <ArayeshgaAros setResponse={setResponse} response={response} />,
    <ArayeshgaDamad setResponse={setResponse} response={response} />,
    <Dastegol setResponse={setResponse} response={response} />,
    <LebasAros setResponse={setResponse} response={response} />,
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = (values) => {
    axios
      .post(
        `${BASE_URL}/api/ai-response`,
        { ...values, licence_id: planId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
        navigate("/dashboard", { replace: false });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="aiPagewapper">
      <main className="aiPage">
        <Formik onSubmit={handleSubmit}>
          <Form className="formInformation">
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
                item
                xs={12}
                md={12}
              >
                <div>{steps[currentStep]}</div>
              </Grid>
            </Grid>
          </Form>
        </Formik>

        <div className="arrowBtns">
          <button
            className="arrowBtn"
            type="button"
            onClick={handleNext}
            disabled={currentStep === steps.length - 1}
          >
            بعدی
          </button>
          <button
            className="arrowBtn"
            type="button"
            onClick={handlePrev}
            disabled={currentStep === 0}
          >
            قبلی
          </button>
        </div>
      </main>
    </div>
  );
};

export default AiPage;
