import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './CustomSelector.css';

const CustomSelector = ({ options, onSelection, placeholder, labelName, valueName, value='' }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (value) {
      const defaultOption = options.find(option => option[valueName] === value);
      setSelectedOption(defaultOption ? { label: defaultOption[labelName], value: defaultOption[valueName] } : null);
    }
  }, [value, options, labelName, valueName]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelection(selectedOption?.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
    }),
  };


  const mappedOptions = options?.map((option) => ({
    label: option[labelName],
    value: option[valueName],
  }));

  return (
    <div className="custom-selector">
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={mappedOptions}
        styles={customStyles}
        placeholder={placeholder}
        isClearable={true}
      />
    </div>
  );
};

export default CustomSelector;
