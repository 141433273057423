const AboutUsComponent = () => {
  return (
    <section>
      <div className="aboutUs">
        <div className="context">
          <h2>به نام عشق</h2>
          به وبسایت ما خوش آمدید. ما در تیم عروسی آنلاین، با افتخار به ارائه‌ی
          جدیدترین و زیباترین مجموعه کارت‌های دعوت عروسی پرداخته‌ایم. با افتخار
          اعلام می‌کنیم که با ترکیب طراحی‌های خلاقانه، کیفیت بالا و راحتی
          استفاده، توانسته‌ایم تجربه‌ی منحصربه‌فردی را برای شما عزیزان فراهم
          آوریم. در تیم ما، افرادی با عشق به هنر طراحی و ارتباط با شما که
          مهمترین رویداد زندگی‌تان را جشن می‌گیرید، گرد هم آمده‌اند. از
          جنسیت‌ها، سبک‌ها و سلیقه‌های مختلف اطلاعات داریم و سعی داریم تا در هر
          لحظه، به تمام تفاصیل کوچک و بزرگ مرتبط با کارت دعوت شما توجه کنیم.
          خدمت به شما و کمک به شما برای انتخاب کارتی که همه‌ی خواسته‌های شما را
          منعکس کند، افتخار ماست. ما به عنوان همراه شما در این مسیر خوشایندی و
          شادی را از طریق انتخاب کارت دعوت می‌پذیریم و تضمین می‌کنیم که تجربه‌ی
          خرید آنلاین شما ساده، دلپذیر و رضایت‌بخش خواهد بود.
          {/* هدف ما در این وبسایت، ایجاد لحظاتی شگفت‌انگیز و به یادماندنی در روز واژگون شماست. همراهی‌تان در این مسیر، برای ما افتخار است و امیدواریم که با انتخاب کارت دعوت از ما، روزی بی‌نظیر و پر از خاطرات خوش در پیش دارید.

                    با تشکر از شما که به ما اعتماد کرده‌اید. */}
        </div>
        <img
          alt=""
          className="image"
          src="/asset/media/image/aros-damad2.jpg"
        />
      </div>
    </section>
  );
};

export default AboutUsComponent;
