import { Grid, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../auth/authContext";
import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const Section2Page = () => {
  const { token } = useAuth();

  const initialValues = {
    mobileA: "",
    mobileD: "",
  };

  const validationSchema = Yup.object({
    mobileA: Yup.number(),
    mobileD: Yup.number(),
  });

  const handleSubmit = (values) => {
    axios
      .post(
        `${BASE_URL}/api/licence/current`,
        {
          mobileA: values?.mobileA,
          mobileD: values?.mobileD,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleMobileChange = (e, setFieldValue) => {
    const { id, value } = e.target;
    if (value.length <= 11) {
      setFieldValue(id, value);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form className="formInformation" style={{ marginTop: "50px" }}>
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid
              sx={{ display: "flex", flexDirection: "column" }}
              item
              xs={12}
              md={7}
            >
              <Typography variant="h4">اطلاعات شما</Typography>
              <Typography variant="p">شماره موبایل عروس و داماد :</Typography>
            </Grid>
            {/* <Grid sx={{ display: "flex", flexDirection: "column" }} item xs={12} md={6}>
                        <label htmlFor="name">نام</label>
                        <Field type="text" id="name" name="name" />
                        <ErrorMessage name="name" component="p" style={{ color: "red", fontSize: "12px" }} />
                    </Grid> */}
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
              item
              xs={12}
              md={7}
            >
              {/* <label htmlFor="mobileA">شماره موبایل عروس</label> */}
              <img
                src="/asset/media/pic/icon/phone.png"
                style={{
                  width: "25px",
                  position: "absolute",
                  left: "30px",
                  top: "25px",
                  borderRight: "1px solid #323232",
                  paddingRight: "3px",
                }}
              />
              <Field
                type="number"
                id="mobileA"
                name="mobileA"
                placeholder="09127654321"
                style={{ textAlign: "left", paddingLeft: "55px" }}
                onChange={(e) => handleMobileChange(e, setFieldValue)}
              />
              <ErrorMessage
                name="mobileA"
                component="p"
                style={{ color: "red", fontSize: "12px" }}
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
              item
              xs={12}
              md={7}
            >
              {/* <label htmlFor="mobileD">شماره موبایل داماد</label> */}
              <img
                src="/asset/media/pic/icon/phone.png"
                style={{
                  width: "25px",
                  position: "absolute",
                  left: "30px",
                  top: "25px",
                  borderRight: "1px solid #323232",
                  paddingRight: "3px",
                }}
              />
              <Field
                type="number"
                id="mobileD"
                name="mobileD"
                placeholder="09127654321"
                style={{ textAlign: "left", paddingLeft: "55px" }}
                onChange={(e) => handleMobileChange(e, setFieldValue)}
              />
              <ErrorMessage
                name="mobileD"
                component="p"
                style={{ color: "red", fontSize: "12px" }}
              />
            </Grid>
            {/* <Grid sx={{ display: "flex", flexDirection: "column" }} item xs={12} md={6}>
                        <label htmlFor="password">رمز عبور</label>
                        <Field type="password" id="password" name="password" />
                        <ErrorMessage name="password" component="p" style={{ color: "red", fontSize: "12px" }} />
                    </Grid> */}

            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
              item
              xs={12}
            >
              <button className="saveInformation" type="submit">
                ذخیره
              </button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default Section2Page;
