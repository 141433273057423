// import { Link } from "react-router-dom";
// import { useAuth } from "../auth/authContext";
// import './Address.css';
// import CircularProgress from '@mui/material/CircularProgress';

// const AddressPage = () => {
//     const { cartData } = useAuth();

//     return (
//         <section className="addressPage">
//             {
//                 cartData ?
//                     <>
//                         <img alt="" src='/asset/media/pic/blue-flowers.jpg' className="topFlowers" />

//                         <div className="names">
//                             <span className="aros" data-aos="fade-left" data-aos-delay="100">{cartData?.bride_name}</span>
//                             <span className="and">و</span>
//                             <span className="damad" data-aos="fade-right" data-aos-delay="200">{cartData?.groom_name}</span>
//                         </div>

//                         <div className="">
//                             <h4 className="talar" data-aos="fade-right">{cartData?.saloon_name}</h4>
//                             <p className="address" data-aos="fade-up">{cartData?.saloon_address}</p>

//                             <p className="choseText" data-aos="fade-up">برنامه مورد نظر را برای مسیریابی انتخاب کنید:</p>

//                             <div className="apps">
//                                 {
//                                     cartData?.balad ?
//                                         <a href={cartData?.balad} target="_blank" data-aos="fade-up" data-aos-duration="500">
//                                             <div>
//                                                 <img alt="" src='/asset/media/icon/balad.png' className="app" />
//                                                 <span>بلد</span>
//                                             </div>
//                                             <img alt="" src='/asset/media/pic/icon/open-link.png' className="icon" />
//                                         </a>
//                                         :
//                                         <></>
//                                 }
//                                 {
//                                     cartData?.neshan ?
//                                         <a href={cartData?.neshan} target="_blank" data-aos="fade-up" data-aos-duration="600">
//                                             <div>
//                                                 <img alt="" src='/asset/media/icon/neshan.png' className="app" />
//                                                 <span>نشان</span>
//                                             </div>
//                                             <img alt="" src='/asset/media/pic/icon/open-link.png' className="icon" />
//                                         </a>
//                                         :
//                                         <></>
//                                 }
//                                 {
//                                     cartData?.waze ?
//                                         <a href={cartData?.waze} target="_blank" data-aos="fade-up" data-aos-duration="700">
//                                             <div>
//                                                 <img alt="" src='/asset/media/icon/waze.png' className="app" />
//                                                 <span>ویز</span>
//                                             </div>
//                                             <img alt="" src='/asset/media/pic/icon/open-link.png' className="icon" />
//                                         </a>
//                                         :
//                                         <></>
//                                 }
//                                 {
//                                     cartData?.google_map ?
//                                         <a href={cartData?.google_map} target="_blank" data-aos="fade-up" data-aos-duration="800">
//                                             <div>
//                                                 <img alt="" src='/asset/media/icon/google-map.png' className="app" />
//                                                 <span>گوگل مپ</span>
//                                             </div>
//                                             <img alt="" src='/asset/media/pic/icon/open-link.png' className="icon" />
//                                         </a>
//                                         :
//                                         <></>
//                                 }
//                             </div>

//                             <div className="links">
//                                 <Link to="/" style={{ color: "#273c75", border: "1px solid #273c75", padding: "2px 5px", borderRadius: "5px",fontSize:"20px",marginLeft:"5px" }}>عروسی آنلاین</Link>
//                                 <Link to="/card" style={{ color: "#273c75", border: "1px solid #273c75", padding: "2px 5px", borderRadius: "5px",fontSize:"20px" }}>کارت عروسی</Link>
//                             </div>
//                         </div>
//                     </>
//                     :
//                     <div style={{ display: 'flex', justifyContent: 'center' }}>
//                         <CircularProgress />
//                     </div>
//             }

//         </section>
//     );
// }

// export default AddressPage;

import { Link } from "react-router-dom";
import { useAuth } from "../auth/authContext";
import "./Address.css";
import CircularProgress from "@mui/material/CircularProgress";

const AddressPage = () => {
  const { cartData } = useAuth();

  return (
    <section className="addressPage">
      <img
        alt=""
        src="/asset/media/pic/blue-flowers.jpg"
        className="topFlowers"
      />

      <div className="names">
        <span className="aros" data-aos="fade-left" data-aos-delay="100">
          فاطمه
        </span>
        <span className="and">و</span>
        <span className="damad" data-aos="fade-right" data-aos-delay="200">
          میلاد
        </span>
      </div>

      <div className="">
        <h4 className="talar" data-aos="fade-right">
          تالار قصر کیان
        </h4>
        <p className="address" data-aos="fade-up">
          خیابان دماوند، بین ایستگاه خاقانی و آیت ،روبروی بانک ملت
        </p>

        <p className="choseText" data-aos="fade-up">
          برنامه مورد نظر را برای مسیریابی انتخاب کنید:
        </p>

        <div className="apps">
          <a
            href="https://balad.ir/p/1GUMfMVULilrVr"
            target="_blank"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <div>
              <img alt="" src="/asset/media/icon/balad.png" className="app" />
              <span>بلد</span>
            </div>
            <img
              alt=""
              src="/asset/media/pic/icon/open-link.png"
              className="icon"
            />
          </a>

          <a
            href="https://nshn.ir/_bvH9UVxpsZd"
            target="_blank"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            <div>
              <img alt="" src="/asset/media/icon/neshan.png" className="app" />
              <span>نشان</span>
            </div>
            <img
              alt=""
              src="/asset/media/pic/icon/open-link.png"
              className="icon"
            />
          </a>

          <a
            href="https://waze.com/ul/htnke5tz9s"
            target="_blank"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            <div>
              <img alt="" src="/asset/media/icon/waze.png" className="app" />
              <span>ویز</span>
            </div>
            <img
              alt=""
              src="/asset/media/pic/icon/open-link.png"
              className="icon"
            />
          </a>
          <a
            href="https://www.google.com/maps?daddr=35.715481,51.492541"
            target="_blank"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <div>
              <img
                alt=""
                src="/asset/media/icon/google-map.png"
                className="app"
              />
              <span>گوگل مپ</span>
            </div>
            <img
              alt=""
              src="/asset/media/pic/icon/open-link.png"
              className="icon"
            />
          </a>
        </div>

        <div className="links">
          <Link
            to="/"
            style={{
              color: "#273c75",
              border: "1px solid #273c75",
              padding: "2px 5px",
              borderRadius: "5px",
              fontSize: "20px",
              marginLeft: "5px",
            }}
          >
            عروسی آنلاین
          </Link>
          {/* <Link to="/card" style={{ color: "#273c75", border: "1px solid #273c75", padding: "2px 5px", borderRadius: "5px", fontSize: "20px" }}>کارت عروسی</Link> */}
        </div>
      </div>
    </section>
  );
};

export default AddressPage;
