import { Route, Routes } from "react-router-dom";
import BlogPage from "../pages/BlogPage";
import FindLocationPage from "../pages/Bloges/FindLocation";
import CardPage from "../pages/Cards/Card/CardPage";
import CardPage3 from "../pages/Cards/Card3/CardPage3";
import CardPage5 from "../pages/Cards/Card5/CardPage5";
import CardPage6 from "../pages/Cards/Card6/CardPage6";
import CardPage7 from "../pages/Cards/Card7/CardPage7";
import CardPage8 from "../pages/Cards/Card8/CardPage8";
import LoginPage from "../components/Login/login";
import SignUpPage from "../components/GetCode/getCode";

import CartPage from "../pages/Cart/CartPage";
import DashboardPage from "../pages/Dashboard/DashboardPage";
import { PrivateRoute } from "./privateRout";
import HomePage from "../pages/HomePage";
import PlanPage from "../pages/Plan/PlanPage";
import guestCardPage from "../pages/Cards/guestCardPage";
import AddressPage from "../pages/Address";
import AboutUsPage from "../pages/AboutUs";
import ContactUsPage from "../pages/ContactUs";
import CardPage2 from "../pages/Cards/Card2/CardPage2";
import CardPage4 from "../pages/Cards/Card4/CardPage4";
import AiPage from "../pages/Ai/AiPage2";
import Success from "../pages/Success";
import UnSuccess from "../pages/UnSuccess";
import ExampleCardPage from "../pages/Cards/ExampleCardPage";
import NotFound404Page from "../pages/NotFound";

const publicRoutes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/blog",
    component: BlogPage,
  },
  {
    path: "/blog/find-location",
    component: FindLocationPage,
  },
  {
    path: "/ai",
    component: AiPage,
  },
  {
    path: "/cart",
    component: CartPage,
  },
  {
    path: "/card",
    component: CardPage,
  },
  {
    path: "/card2",
    component: CardPage2,
  },
  {
    path: "/card3",
    component: CardPage3,
  },
  {
    path: "/card4",
    component: CardPage4,
  },
  {
    path: "/card5",
    component: CardPage5,
  },
  {
    path: "/card6",
    component: CardPage6,
  },
  {
    path: "/card7",
    component: CardPage7,
  },
  {
    path: "/card8",
    component: CardPage8,
  },
  {
    path: "/example-card",
    component: ExampleCardPage,
  },
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/plan/:planId",
    component: PlanPage,
  },
  {
    path: "/verify",
    component: SignUpPage,
  },
  {
    path: "/about-us",
    component: AboutUsPage,
  },
  {
    path: "/contact-us",
    component: ContactUsPage,
  },
  {
    path: "/fateme-milad/address",
    component: AddressPage,
  },
  {
    path: "/:username/:parameter",
    component: guestCardPage,
  },
  {
    path: "/payment/success/:message",
    component: Success,
  },
  {
    path: "/payment/failed/:message",
    component: UnSuccess,
  },
  {
    path: "/dashboard",
    component: DashboardPage,
  },
];

const privateRoutes = [];

export const GeneralPublicRoutes = () => (
  <Routes>
    {publicRoutes.map(({ path, component: Component }, i) => {
      return <Route key={i} path={path} element={<Component />} />;
    })}
    <Route path="*" element={<NotFound404Page />} />
  </Routes>
);

// export const GeneralPrivateRoutes = () => (
//   <Routes>
//     {privateRoutes.map(({ path, component: Component }, i) => (
//       <Route key={i} path={path} element={<PrivateRoute><Component /></PrivateRoute>} />
//     ))}
//   </Routes>
// );
export const GeneralPrivateRoutes = () => (
  <Routes>
    {privateRoutes.map(({ path, component: Component }, i) => {
      return (
        <Route
          key={i}
          path={path}
          element={
            <PrivateRoute>
              <Component />
            </PrivateRoute>
          }
        />
      );
    })}
  </Routes>
);
