import jalaliMoment from "jalali-moment";
import numberToPersian from "../../../../common/numberToPersian";

const Section1Page = ({ cartData }) => {
  const persianDate = jalaliMoment(cartData?.date || "2025-01-26")
    .locale("fa")
    .format("YYYY/MM/DD");

  return (
    <div className="sectionOne">
      <img
        src="/asset/media/pic/cart3/cart3-2.png"
        className="cardImage1"
        alt=""
      />
      <img
        src="/asset/media/pic/cart3/cart3-1.png"
        className="cardImage2"
        alt=""
      />

      {/* <img src="/asset/media/image/aros-damad2.jpg" className="arosImage" alt="" data-aos="fade-up" data-aos-delay="100" />
      <img src="/asset/media/image/aros-damad2.jpg" className="damadImage" alt="" data-aos="fade-up" data-aos-delay="200" /> */}

      {cartData ? (
        <>
          {Number(cartData?.show_eng_names) ? (
            <div className="namesEng">
              <div className="names">
                <span
                  className="aros"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  {cartData?.bride_name_eng}
                </span>
                <span className="and">&</span>
                <span
                  className="damad"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  {cartData?.groom_name_eng}
                </span>
              </div>
              <div className="FamilyNames">
                <span
                  className="aros"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  {cartData?.bride_lastname_eng}
                </span>
                <span className="and">&</span>
                <span
                  className="damad"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  {cartData?.groom_lastname_eng}
                </span>
              </div>
            </div>
          ) : (
            <>
              <div className="names">
                <span
                  className="aros"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  {cartData?.bride_name}
                </span>
                <span className="and">و</span>
                <span
                  className="damad"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  {cartData?.groom_name}
                </span>
              </div>
              <div className="FamilyNames">
                <span
                  className="aros"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  {cartData?.bride_lastname}
                </span>
                <span className="and">و</span>
                <span
                  className="damad"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  {cartData?.groom_lastname}
                </span>
              </div>
            </>
          )}
          <p className="text">{cartData?.description}</p>
          <div className="boxDetail">
            <p
              className="detail detail1"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {jalaliMoment(cartData?.date).locale("fa").format("dddd")}
            </p>
            <p
              className="detail detail2"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <span>
                {numberToPersian(
                  jalaliMoment(persianDate, "jYYYY/jMM/jDD").format("jDD")
                )}
              </span>
              <span>
                {jalaliMoment(persianDate, "jYYYY/jMM/jDD").format("jMMMM")}
              </span>
              <span>
                {numberToPersian(
                  jalaliMoment(persianDate, "jYYYY/jMM/jDD").format("jYYYY")
                )}
              </span>
            </p>
            <p
              className="detail detail3"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              {cartData?.from_time
                ? numberToPersian(cartData?.from_time?.substring(0, 5))
                : ""}{" "}
              -{" "}
              {cartData?.to_time
                ? numberToPersian(cartData?.to_time?.substring(0, 5))
                : ""}
            </p>
          </div>
          <p className="detail" data-aos="fade-up" data-aos-delay="300">
            به صرف {cartData?.foods}
          </p>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Section1Page;
