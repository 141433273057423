const Input = ({
  label,
  name,
  formik,
  type = "text",
  placeholder = "",
  max = "",
  disabled = false,
}) => {
  return (
    <div className="formControl">
      <label htmlFor="name">{label}</label>
      <input
        id={name}
        type={type}
        {...formik.getFieldProps(name)}
        name={name}
        placeholder={placeholder}
        max={max}
        disabled={disabled}
      />
      {formik.errors[name] && formik.touched[name] && (
        <div className="error">{formik.errors[name]}</div>
      )}
    </div>
  );
};

export default Input;
