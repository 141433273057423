import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Field, ErrorMessage } from "formik";
import { TypeAnimation } from "react-type-animation";
import CustomSelector from "../../components/Selector/CustomSelector.jsx";
import { Button } from "@mui/material";

const foodOptions = [
  { id: 1, label: "food1", value: "سلف سرویس" },
  {
    id: 2,
    label: "food2",
    value: "یک نوع غذا اقتصادی(مثل جوجه کباب یا زرشک پلو با مرغ)",
  },
  { id: 3, label: "food3", value: "یک نوع غذا گوشتی" },
  { id: 4, label: "food4", value: "دو نوع غذا متوسط" },
  { id: 5, label: "food5", value: "دو نوع غذا گران" },
  { id: 6, label: "food6", value: "سه نوع غذا متوسط" },
  { id: 7, label: "food7", value: "سه نوع غذا گران" },
];
const talarOptions = [
  { id: 1, label: "talar1", value: "باغ تالار سوپر لاکچری" },
  { id: 2, label: "talar2", value: "باغ تالار لاکچری" },
  { id: 3, label: "talar3", value: "باغ تالار متوسط" },
  { id: 4, label: "talar4", value: "باغ تالار اقتصادی" },
  { id: 5, label: "talar5", value: "تالار لاکچری" },
  { id: 6, label: "talar6", value: "تالار متوسط" },
  { id: 7, label: "talar7", value: "تالار اقتصادی" },
];
const atelieOptions = [
  { id: 1, label: "atelie1", value: "زیر ۲۵ میلیون" },
  { id: 2, label: "atelie2", value: "۲۵ - ۳۵ میلیون" },
  { id: 3, label: "atelie3", value: "بالای ۳۵ میلون" },
];

const mashinGolOptions = [
  {
    id: 1,
    label: "mashinGol1",
    value: "گل های اقتصادی مثل داوودی (حدودا زیر ۲ میلیون)",
  },
  {
    id: 2,
    label: "mashinGol2",
    value: "گل های ترکیبی (مثلا استفاده از رز حدود ۲-۵ میلیون)",
  },
  { id: 3, label: "mashinGol3", value: "ماشین عروس لاکچری (بالای ۵ میلیون)" },
];

const arayeshgaArosOptions = [
  {
    id: 1,
    label: "arayeshgaAros1",
    value: "آرایشگاه عروس اقتصادی (حدودا زیر ۷ میلیون)",
  },
  { id: 2, label: "arayeshgaAros2", value: "پکیج vip (حدود 8-12 میلیون)" },
  { id: 3, label: "arayeshgaAros3", value: "پکیج لوکس (بالای 12 میلیون)" },
];

const arayeshgaDamadOptions = [
  { id: 1, label: "arayeshgaDamad1", value: "زیر ۲ میلیون" },
  { id: 2, label: "arayeshgaDamad2", value: " ۲ - ۵ میلیون" },
  { id: 3, label: "arayeshgaDamad3", value: "۵ میلیون به بالا" },
];

const dastegolOptions = [
  { id: 1, label: "dastegol1", value: "اقتصادی کمتر از ۲ میلیون" },
  { id: 2, label: "dastegol2", value: "دسته گل نرمال بین ۲-۶ میلیون" },
  {
    id: 3,
    label: "dastegol3",
    value: "دسته گل های خاص مثل پیونی بالای ۶ میلیون",
  },
];

export const NumberOfGuest = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <TypeAnimation
        sequence={[
          `سلام خیلی خوش آمدید.
          حدود ۲۰ تا سوال ازتون میپرسم تا بتونم با دقت بیشتری پاسخگو باشم.
          لطفا با حوصله جواب بدید. 
           تعداد مهمان‌ها را وارد کنید:`,
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <Field
          type="number"
          id="numberOfGuest"
          name="numberOfGuest"
          placeholder=""
          value={response.numberOfGuest}
          onChange={(e) =>
            setResponse({ ...response, numberOfGuest: e.target.value })
          }
          style={{ textAlign: "left", paddingLeft: "55px" }}
        />
      )}
    </>
  );
};

export const Food = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <TypeAnimation
        sequence={[
          "لطفا نوع غذا را انتخاب کنید",
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <Box style={{ width: "-webkit-fill-available" }}>
          <CustomSelector
            options={foodOptions}
            placeholder={"غذای مورد نظر را انتخاب کنید"}
            onSelection={(e) => setResponse({ ...response, kindOfFood: e })}
            labelName="value"
            valueName="value"
            value={response.kindOfFood}
            style={{ width: "50px" }}
          />
        </Box>
      )}
    </>
  );
};

export const Talar = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <TypeAnimation
        sequence={[
          "لطفا رتبه تالار را انتخاب کنید",
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <Box style={{ width: "-webkit-fill-available" }}>
          <CustomSelector
            options={talarOptions}
            placeholder={"نوع تالار را انتخاب کنید"}
            onSelection={(e) => setResponse({ ...response, talar: e })}
            labelName="value"
            valueName="value"
            value={response.talar}
            style={{ width: "50px" }}
          />
        </Box>
      )}
    </>
  );
};

export const Atelie = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <TypeAnimation
        sequence={[
          "همونطور که میدونید بازه قیمتی آتلیه خیلی متنوع است. حدود هزینه که مد نظر دارید رو مشخص کنید لطفا:",
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <Box style={{ width: "-webkit-fill-available" }}>
          <CustomSelector
            options={atelieOptions}
            placeholder={"نوع آتلیه را انتخاب کنید"}
            onSelection={(e) => setResponse({ ...response, atelie: e })}
            labelName="value"
            valueName="value"
            value={response.atelie}
            style={{ width: "50px" }}
          />
        </Box>
      )}
    </>
  );
};

export const Mashin = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  const handleSelection = (value) => {
    setResponse({ ...response, mashin: value });
  };

  return (
    <>
      <TypeAnimation
        sequence={[
          `لطفا بهم بگید که خودتون ماشین دارید(یا مثلا از نزدیکانتون قرض میگیرید)
           یا قصد دارید ماشین اجاره کنید:`,
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <div style={{ textAlign: "left" }}>
          <Button
            variant={
              response.mashin === "ماشین دارم" ? "contained" : "outlined"
            }
            color="warning"
            onClick={() => handleSelection("ماشین دارم")}
            style={{ marginLeft: "10px" }}
          >
            ماشین دارم
          </Button>
          <Button
            variant={
              response.mashin === "اجاره میکنم" ? "contained" : "outlined"
            }
            color="warning"
            onClick={() => handleSelection("اجاره میکنم")}
          >
            اجاره می‌کنم
          </Button>
        </div>
      )}
    </>
  );
};

export const MashinGol = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <TypeAnimation
        sequence={[
          "گل آرایی ماشین عروس مد نظرتون رو بهم بگید لطفا",
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <Box style={{ width: "-webkit-fill-available" }}>
          <CustomSelector
            options={mashinGolOptions}
            placeholder={"گل آرایی ماشین عروس"}
            onSelection={(e) => setResponse({ ...response, mashinGol: e })}
            labelName="value"
            valueName="value"
            value={response.mashinGol}
            style={{ width: "50px" }}
          />
        </Box>
      )}
    </>
  );
};

export const ArayeshgaAros = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <TypeAnimation
        sequence={[
          "طبق داده های دریافتی قیمت ارایشگاه عروس رو میشه توی این سه مورد دسته بندی کرد.کدوم مورد مد نظرتونه؟",
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <Box style={{ width: "-webkit-fill-available" }}>
          <CustomSelector
            options={arayeshgaArosOptions}
            placeholder={"آرایشگاه عروس"}
            onSelection={(e) => setResponse({ ...response, arayeshgaAros: e })}
            labelName="value"
            valueName="value"
            value={response.arayeshgaAros}
            style={{ width: "50px" }}
          />
        </Box>
      )}
    </>
  );
};

export const ArayeshgaDamad = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <TypeAnimation
        sequence={[
          "آرایشگاه داماد چطور؟",
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <Box style={{ width: "-webkit-fill-available" }}>
          <CustomSelector
            options={arayeshgaDamadOptions}
            placeholder={"آرایشگاه داماد"}
            onSelection={(e) => setResponse({ ...response, arayeshgaDamad: e })}
            labelName="value"
            valueName="value"
            value={response.arayeshgaDamad}
            style={{ width: "50px" }}
          />
        </Box>
      )}
    </>
  );
};

export const Dastegol = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <TypeAnimation
        sequence={[
          "طبق داده ها تونستم دسته گل عروس رو به صورت زیر دسته بندی کنم",
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <Box style={{ width: "-webkit-fill-available" }}>
          <CustomSelector
            options={dastegolOptions}
            placeholder={"دسته گل عروس"}
            onSelection={(e) => setResponse({ ...response, dastegol: e })}
            labelName="value"
            valueName="value"
            value={response.dastegol}
            style={{ width: "50px" }}
          />
        </Box>
      )}
    </>
  );
};

export const LebasAros = ({ setResponse, response }) => {
  const [showInput, setShowInput] = useState(false);
  const handleSelection = (value) => {
    setResponse({ ...response, lebasAros: value });
  };

  return (
    <>
      <TypeAnimation
        sequence={[
          `دوست دارین لباس عروس رو بخرید یا اجاره کنید`,
          () => {
            setShowInput(true);
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={1}
        style={{
          fontSize: "2em",
          display: "inline-block",
          marginBottom: "15px",
        }}
      />
      {showInput && (
        <div style={{ textAlign: "left" }}>
          <Button
            variant={
              response.lebasAros === "قصد خرید دارم" ? "contained" : "outlined"
            }
            color="warning"
            onClick={() => handleSelection("قصد خرید دارم")}
            style={{ marginLeft: "10px" }}
          >
            قصد خرید دارم
          </Button>
          <Button
            variant={
              response.lebasAros === "اجاره میکنم" ? "contained" : "outlined"
            }
            color="warning"
            onClick={() => handleSelection("اجاره میکنم")}
          >
            اجاره می‌کنم
          </Button>
        </div>
      )}
    </>
  );
};
